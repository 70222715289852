<template>
  <div class="page">
    <div class='head'>
      <div class="title">
        <p>起名问答</p>
      </div>
    </div>
    <van-skeleton v-if="loading" :row="5" />
    <div v-else>
              <router-link :class="'one_name background_'+ item.color"  v-for="( item,index) in list.best" :key="index" :to="{name : 'name_detail', query : {name : item.word}}" >{{item.word}}</router-link>
      <div v-for="( item,index) in list" :key="index" class="med qa">
        <p class="title">{{item.title}}</p>
        <p class="desc">{{item.content}}</p>
      </div>

      <p v-if="page === -1"  class="end">-- End --</p>
      <p v-if="page!== -1" class="more"  @click="get_list">更 多</p>
      <ul id="last_right_b" class="last_right">
        <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'

export default {
  name: 'group_qa',
  store,
  components: {},
  data () {
    return {
      show: { zc: false },
      loading: true,
      loading2: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      list: [],
      page: 1
    }
  },

  mounted () {
    this.get_list()
  },
  methods: {
    get_list () {
      this.loading2 = true
      axios.post(!this.niming ? '/qa_list/' : '/qa_list_unlogin/', { page: this.page })
        .then(res => {
          this.loading = false
          this.loading2 = false
          const ResList = res.data.data.list
          if (ResList.length === 20) {
            this.list = this.list.concat(ResList)
            this.page = this.page + 1
          } else if (ResList.length !== 0) {
            this.list = this.list.concat(ResList)
            this.page = -1
          } else {
            this.page = -1
          }
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    on_close () {
      this.show = { zc: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    }
  }
}
</script>

<style>
.qa{
  padding: 0.4rem 0.4rem;
}
.qa .title{
  color: #A6CBE9;
  padding-bottom: 0.3rem;
}
</style>
